import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { WorkflowList } from './WorkflowList';
import { WorkflowHistory } from './WorkflowHistory';
import axios from 'axios';

// import Utilities
import {
    ToTitleCase,
    FormatDate,
    FormatTime,
    FormatStatus,
    GetInitials
} from 'helpers/Formatters/Format';

const Workfow = () => {
    document.title = "Workflow | TFS Processing App";
    const [completedWorkflows, setCompletedWorkflows] = useState([]);
    const [inProgressWorkflows, setInProgressWorkflows] = useState([]);


    const separateWorkflowsByStatus = (response) => {
        const completed = [];
        const inProgress = [];

        response?.forEach(item => {
            const formattedWorkflow = {
                name: ToTitleCase(item?.workflow_name),  // Convert workflow_name to title case
                date: FormatDate(item?.start_time),  // Format start_time to MM-DD-YYYY
                time: FormatTime(item?.start_time),  // Format time to AM/PM
                processor: GetInitials(item?.processor_name),  // Get initials from processor_name
                current_Task: item?.current_running_task || '',  // Handle missing current_running_task
                status: FormatStatus(item?.status),
                tasks: item?.tasks                // Format status
            };

            if (item?.status === 'completed') {
                completed.push(formattedWorkflow);
            } else {
                inProgress.push(formattedWorkflow);
            }
        });

        return { completed, inProgress };
    };

    useEffect(() => {

        const fetchData = async () => {
            try {

                const url = `${window.env.REACT_APP_BASE_URL}/workflow/getWorkflows`;  // Replace with your URL

                const token = sessionStorage.getItem('authToken')

                // Make the GET request with the correct URL and headers
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const { completed, inProgress } = separateWorkflowsByStatus(response);

                setCompletedWorkflows(completed)
                setInProgressWorkflows(inProgress)

            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Error fetching data:', error);
            }
        };
        fetchData()
    }, [])

    const removeSingleQuotes = (str) => str.replace(/'/g, '"');

    useEffect(() => {
        const token = sessionStorage.getItem('authToken') || ''
        if (token) {
            const eventSource = new EventSource(`${window.env.REACT_APP_BASE_URL}/workflow/status`);

            eventSource.onmessage = (event) => {
                try {
                    const result = removeSingleQuotes(event.data);
                    const parsedData = JSON.parse(result);

                    const { completed, inProgress } = separateWorkflowsByStatus(parsedData);

                    setCompletedWorkflows(completed)
                    setInProgressWorkflows(inProgress)

                    // checkForFailure();
                } catch (error) {
                    console.error('Error parsing JSON:', error, event.data);
                }
            };

            eventSource.onerror = (error) => {
                console.error('EventSource failed:', error);
                eventSource.close();
            };

            return () => {
                eventSource.close();
            };

        }
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Workflow" pageTitle="Workflow" />
                    {/* Workflow List */}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Workflow List</h5>
                                </CardHeader>
                                <CardBody>
                                    <WorkflowList workflowData={inProgressWorkflows} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Workflow History */}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Workflow History</h5>
                                </CardHeader>
                                <CardBody>
                                    <WorkflowHistory workflowData={completedWorkflows} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default Workfow;
