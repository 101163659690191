import React, { useEffect, useMemo, useState } from 'react';
import TableContainer from "../../Components/Common/TableContainerReactTable";

type ActionData = {
    Action: string;
    "Task Name": string;
    Type: string;
    Time: string;
    "Time Elapsed": string;
    task_uuid: string;
};

type Props = {
    setOpenTab: any;
    actionsData: any;
    setActionsData :any;
    setActionsName: any;
    setActionTaskId: any;
    setActionUrl:any;
};

export const ActionList: React.FC<Props> = ({ setOpenTab, setActionsData, actionsData, setActionsName, setActionTaskId, setActionUrl }) => {
    // const [actionsData, setActionsData] = useState<ActionData[]>([]); // Dynamic data from SSE

    useEffect(() => {
        // Set up the EventSource to listen for server-sent events
        const eventSource = new EventSource(`${window.env.REACT_APP_BASE_URL}/workflow/manualTask/stream`);

        eventSource.onmessage = (event) => {
            // Log the raw event data
            console.log("Raw event data:",event.data);

            try {
                // Replace single quotes with double quotes for valid JSON (if necessary)
                const sanitizedData = event.data.replace(/'/g, '"');

                // Parse the event data (assuming it's now valid JSON)
                const tasks = JSON.parse(sanitizedData);
                console.log(tasks)
                // Ensure the received data is an array
                if (Array.isArray(tasks)) {
                    // Map each task in the array to the ActionData structure
                    const newActions = tasks?.map((task: any) => ({
                        Action: task.data?.task_name,
                        "Task Name": "-",
                        Type: "inbound",
                        Time: new Date(task?.datetime).toLocaleTimeString(),
                        "Time Elapsed": "-",
                        task_uuid: task?.data.task_uuid, // For uniqueness
                        task_url : task?.data.action_url
                    }));

                    setActionsData(newActions)
                } else {
                    console.error("Unexpected data structure:", tasks);
                }
            } catch (error) {
                console.error("Failed to parse event data:", error);
            }
        };


        return () => {
            console.log("complete");
            eventSource.close();
        };
    }, []);

    const columns = useMemo(
        () => [
            {
                header: "Action",
                accessorKey: "Action",
                enableColumnFilter: false,
            },
            {
                header: "Task Name",
                accessorKey: "Task Name",
                enableColumnFilter: false,
            },
            {
                header: "Type",
                accessorKey: "Type",
                enableColumnFilter: false,
            },
            {
                header: "Time",
                accessorKey: "Time",
                enableColumnFilter: false,
            },
            {
                header: "Time Elapsed",
                accessorKey: "Time Elapsed",
                enableColumnFilter: false,
                cell: ({ cell }: { cell: any }) => (
                    <div className="d-flex justify-content-between">
                        {cell.getValue()}
                        <i
                            className="ri-skip-forward-mini-line"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setActionUrl(cell.row.original.task_url)
                                setActionTaskId(cell.row.original.task_uuid)
                                setActionsName(cell.row.original.Action)
                                setOpenTab(true);
                            }}
                        ></i>
                    </div>
                ),
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={actionsData} // Use dynamic data here
                customPageSize={20}
                SearchPlaceholder="Search..."
                showPagination={false}
            />
        </React.Fragment>
    );
};
