import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";

// Dashboard Cryto
import DashboardProjectReducer from "./dashboardProject/reducer";


// API Key
import APIKeyReducer from "./apiKey/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    DashboardProject: DashboardProjectReducer,
    APIKey: APIKeyReducer
});

export default rootReducer;