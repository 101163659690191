import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

// import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { CardProgress } from './Card';
import { useLocation } from 'react-router-dom';
import { GetStatiscs } from './GetStatistics';

const InboundPaymentProcessing = () => {
    const location = useLocation();
    const state = location?.state
 
    document.title = "Inbound Payment Processing | TFS Processing App";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Inbound Payment Processing" pageTitle="Workflow" />

                    {/* Workflow List */}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">{state?.key?.name}</h5>
                                </CardHeader>
                                {state?.key?.tasks?.map((task: any) => (
                                    <CardProgress taskData={task} />
                                ))}
                            </Card>
                        </Col>
                    </Row>
                    {state?.key?.tasks?.map((task: any) => {
                        if (task?.task_name === "create_bundle" && task?.status === "ready") {
                            return (<Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Statistics</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <GetStatiscs/>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>)
                        }
                    })}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default InboundPaymentProcessing;
