
import TableContainer from 'Components/Common/TableContainerReactTable';
import React, { useMemo } from 'react';

export const GetStatiscs = () => {

    // TODO: Get Statistics API 

    const columns = useMemo(
        () => [
            {
                header: "Total Transactions",
                accessorKey: "total_transactions",
                enableColumnFilter: false,
            },
            {
                header: "Total Amount",
                accessorKey: "total_amount",
                enableColumnFilter: false,
            },
            {
                header: "Total Fees",
                accessorKey: "total_fees",
                enableColumnFilter: false,
            },
            {
                header: "Refund Count",
                accessorKey: "refund_count",
                enableColumnFilter: false,
            },
            {
                header: "Refund Fee Amount",
                accessorKey: "refund_fee_amount",
                enableColumnFilter: false,
            }
        ],
        []
    );

    const statsDataDummy = [
        {
            total_transactions: 32,
            total_amount: 23452.33,
            total_fees: 144.34,
            refund_count: 0,
            refund_fee_amount: 0.00
        }
    ]

    return (
        <React.Fragment >
            <TableContainer
                columns={(columns || [])}
                data={(statsDataDummy || [])}
                customPageSize={5}
                SearchPlaceholder='Search Products...'
                showPagination
            />
        </React.Fragment >
    );
}