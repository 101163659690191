import React, { useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { Card, CardBody, CardHeader, Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane, Button, Label } from 'reactstrap';
import classnames from 'classnames';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { ActionList } from './ActionList';
import { jwtDecode } from 'jwt-decode';

type ActionData = {
    Action: string;
    "Task Name": string;
    Type: string;
    Time: string;
    "Time Elapsed": string;
    task_uuid: string;
};

const Action = () => {
    document.title = "Workflow | TFS Processing App";

    const [activeTab, setActiveTab] = useState<string>('1');
    const [openTab, setOpenTab] = useState(false);
    const [actionsData, setActionsData] = useState<ActionData[]>([]);
    const [actionName,setActionsName] = useState<string>("");
    const [actionTaskId,setActionTaskId] = useState<string>("");
    const [actionUrl,setActionUrl] = useState<string>("");
    const [authtoken, setauthToken] = useState();

    console.log("actionsData",actionsData)
    // Navigate for redirecting
    const navigate = useNavigate();

    const toggle = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    
    // const handleStartButtonClick = async () => {
    //     try {
    //         // Make the API call
    //         const response = await fetch('http://localhost:8000/tickets/getall');
    //         if (response.ok) {
    //             const data = await response.json();
    //             console.log("API Response:", data);

    //             // Redirect to the InboundPaymentProcessing page
    //             navigate('/inbound-payment-processing'); // Use the relative path from the route
    //         } else {
    //             console.error('Failed to fetch workflows');
    //         }
    //     } catch (error) {
    //         console.error('Error during API call:', error);
    //     }
    // };


    const handleStartButtonClick = async (human_task_id: string) => {
        try {    
            const token = sessionStorage.getItem('authToken')
            
            const response = await fetch(actionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${token}`,  // Basic Auth header
                },
                body: JSON.stringify({
                    start_workflow_human_task_id: human_task_id // Pass the human_task_id in request body
                }),
            });
    
            // Check if the response is successful (204 No Content indicates success in your case)
            if (response.ok) {
                console.log('Workflow started successfully.');
    
                // Redirect to the InboundPaymentProcessing page
                navigate('/inbound-payment-processing');
            } else {
                console.error('Failed to start workflow');
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Action List" pageTitle="Action summary" />
                    <Row>
                        <Col xxl={openTab ? 6 : 12}>
                            <Card>
                                <CardBody>
                                    <Nav tabs className="nav-tabs-custom nav-success mb-3">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => toggle('1')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                ALL
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => toggle('2')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                PENDING
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <ActionList setActionTaskId={setActionTaskId} setActionUrl={setActionUrl} setActionsName={setActionsName} actionsData={actionsData} setActionsData={setActionsData}  setOpenTab={setOpenTab} />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <p>No pending actions available.</p>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                        {openTab && (
                            <Col xxl={6}>
                                <Card>
                                    <CardHeader>
                                        <i
                                            className="ri-close-fill me-4 text-danger rounded-2 p-1"
                                            style={{ backgroundColor: "#FCE8E4", cursor: "pointer" }}
                                            onClick={() => setOpenTab(false)}
                                        ></i>
                                        ACTION
                                    </CardHeader>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <Label>{actionName}</Label>
                                            <Button
                                                style={{ background: '#31506A' }}
                                                onClick={()=>handleStartButtonClick(actionTaskId)} // Call the handler
                                            >
                                                Start
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Action;
