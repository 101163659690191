// Utility function to format date in American format (MM-DD-YYYY)
export const FormatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
};

// Utility function to format time in AM/PM
export const FormatTime = (dateString: string) => {
    const date = new Date(dateString);
    let hours = date?.getHours();
    const minutes = date?.getMinutes()?.toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;  // Convert to 12-hour format
    return `${hours}:${minutes} ${ampm}`;
};

// Utility function to get initials of processor name
export const GetInitials = (name: string) => {
    return name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('');
};

// Utility function to format status
export const FormatStatus = (status: string) => {
    return status?.replace('_', ' ')?.replace(/\b\w/g, char => char.toUpperCase());  // Capitalize first letter
};

// Utility function to capitalize the first letter of each word in the workflow name
export const ToTitleCase = (str: string) => {
    return str?.replace(/\w\S*/g, (txt) => txt?.charAt(0)?.toUpperCase() + txt.substr(1).toLowerCase());
};