import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isWorkflow, setIsWorkflow] = useState<boolean>(false);


    // Pages

    const [iscurrentState, setIscurrentState] = useState("Dashboard");

    useEffect(() => {
        document.body.classList.remove("twocolumn-panel");
        if (iscurrentState === "Workflow") {
            history("/workflow");
            document.body.classList.add("twocolumn-panel");
        }
        if (iscurrentState === "Inbound Payment Processing") {
            history("/inbound-payment-processing");
            document.body.classList.add("twocolumn-panel");
        }
    }, [
        history,
        iscurrentState,
        isWorkflow
    ]);

    const menuItems: any = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "workflow",
            label: "Workflow",
            // icon: "mdi mdi-puzzle-outline",
            icon: "rmx ri-git-pull-request-line",
            link: "/workflow",
            click: function (e: any) {
                e.preventDefault();
                setIscurrentState("Workflow");
            },
        },
        {
            id: "action",
            label: "Action",
            // icon: "mdi mdi-puzzle-outline",
            icon: "rmx ri-task-line",
            link: "/action",
            click: function (e: any) {
                e.preventDefault();
                setIscurrentState("Workflow");
            },
        },
        {
            id: "tickets",
            label: "Tickets",
            // icon: "mdi mdi-puzzle-outline",
            icon: "rmx ri-ticket-line",
            link: "/tickets",
            click: function (e: any) {
                e.preventDefault();
                setIscurrentState("Workflow");
            },
        },
        {
            id: "reports",
            label: "Reports",
            // icon: "mdi mdi-puzzle-outline",
            icon: "rmx ri-file-chart-line",
            link: "/report",
            click: function (e: any) {
                e.preventDefault();
                setIscurrentState("Workflow");
            },
        }
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
