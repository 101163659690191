import { FormatStatus, FormatTime } from "helpers/Formatters/Format"
import { Card, CardBody, Progress, Button } from "reactstrap"

export const CardProgress = ({ taskData }: any) => {
    return (
        <CardBody>
            <div className="live-preview">
                <Card className="bg-light overflow-hidden shadow-none">
                    <CardBody>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <div
                                    className="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div
                                        className="avatar-title bg-green-500 rounded-circle fs-16 text-primary">
                                        <i className="ri-folder-line text-light"></i>
                                    </div>
                                </div>
                                <h6 className="mb-0 text-capitalize"><b>{taskData?.task_name}</b></h6>
                            </div>
                            <h6 className="mb-0"><b>{taskData?.task_id}</b></h6>
                            <h6 className="mb-0"><b>{taskData?.start_time !== "" && FormatTime(taskData?.start_time)}</b></h6>
                            {
                                taskData?.status === "start" || taskData?.status === "approve" ?
                                    (<Button color="primary"> {FormatStatus(taskData?.status)} </Button>) : (
                                        <h2 className="badge border border-secondary text-secondary">{FormatStatus(taskData?.status)}</h2>
                                    )
                            }
                        </div>
                    </CardBody>
                    <div>
                        <Progress value={taskData?.status==="ready"? 10:60} color="success" className="bg-success-subtle rounded-0" />
                    </div>
                </Card>
            </div>
        </CardBody>)
}